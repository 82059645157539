<template>
    <div v-bind:class="promoClass" @click="choseTypeOfPackage" @dblclick="$emit('toNextStep')">
        <div v-bind:class="headOfPromoImgClass">
          <p v-bind:class="promoNumberPaddingClass">{{ oneCallCost }}<span class="rubleSign"> &#8381;</span></p>
            <p v-bind:class="oneCallCostTextClass">За результативный звонок!</p>
        </div>
        <section class="bodyOfPromo">
            <div class="costOfPromoBlock">
                <span class="costOfPromo">От {{ price }} руб !</span>
                <p>за 500 компаний</p>
            </div>
            <div class="promoProperty" v-if="oneCallCost === '35'">
                <p class="tickSmall">База контактов бесплатно</p>
                <p class="tickSmall">Полная отчетность (xls)</p>
                <p class="tickSmall">Записи звонков в .mp3</p>
                <p class="tickSmall">Контроль качества звонков</p>
                <p class="tickSmall">Сбор E-mail для отправки КП</p>
            </div>
            <div class="promoProperty" v-if="oneCallCost === '56'">
              <p class="tickSmall">База контактов бесплатно</p>
              <p class="tickSmall">Полная отчетность (xls)</p>
              <p class="tickSmall">Записи звонков в .mp3</p>
              <p class="tickSmall">Контроль качества звонков</p>
              <p class="tickSmall">Сбор E-mail для отправки КП</p>
              <p class="tickSmall">Сбор прямых контактов</p>
              <p class="tickSmall">Дозвон до ЛПР</p>
              <p class="tickSmall">Отработка до 5 возражений/вопросов</p>
            </div>
            <div class="promoProperty" v-if="oneCallCost === '58'">
              <p class="tickSmall">База контактов бесплатно</p>
              <p class="tickSmall">Полная отчетность (xls)</p>
              <p class="tickSmall">Записи звонков в .mp3</p>
              <p class="tickSmall">Контроль качества звонков</p>
              <p class="tickSmall">Сбор E-mail для отправки КП</p>
              <p class="tickSmall">Сбор прямых контактов</p>
              <p class="tickSmall">Дозвон до ЛПР</p>
              <p class="tickSmall">Отработка до 10 возражений/вопросов</p>
              <p class="tickSmall">Мгновенное получение горячих клиентов</p>
              <p class="tickSmall">Доп.прозвон</p>
            </div>

          <div class="promoProperty off" v-if="oneCallCost === '35'">
            <p class="modeSmallOption">Сбор прямых контактов</p>
            <p class="modeSmallOption">Дозвон до ЛПР</p>
            <p class="modeSmallOption">Отработка возражений/вопросов</p>
            <p class="modeSmallOption">Мгновенное получение горячих клиентов</p>
            <p class="modeSmallOption">Доп.прозвон</p>
          </div>
          <div class="promoProperty off" v-if="oneCallCost === '56'">
            <p class="modeSmallOption">Мгновенное получение горячих клиентов</p>
            <p class="modeSmallOption">Доп.прозвон</p>
          </div>
          <div v-if="chosen" @click="$emit('toNextStep')" class="chosenButton">
            <span class="chosenPromoBlock">ВЫБРАНО</span>
          </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Promotion',

    props: {
        typeOfPackage: [String, null],
        oneCallCost: [String, Number],
        price: [String, Number],
        chosen: Boolean,
        mode: String,
        reducePromoHeight: Boolean,
    },

    computed: {
      promoClass() {
          return {
              chosenPromo: this.chosen,
              notChosenPromo: !this.chosen,
              promo: true,
              promoDefault30: this.oneCallCost === '30' && !this.reducePromoHeight &&
                (this.typeOfPackage === '' || this.typeOfPackage === 'medium'),
              promo30: this.oneCallCost === '30',
          };
      },

        headOfPromoImgClass() {
            return {
                modeSmallImg: this.mode === 'small' && this.oneCallCost !== '40',
                modeBigImg: this.mode === 'big',
                modeSmallImgPromo40: this.oneCallCost === '40',
                headOfPromo: true,
            };
        },

        promoNumberPaddingClass() {
            return {
                modeSmallPadding: this.mode === 'small',
                modeBigPadding: this.mode === 'big',
                promoNumber: true,
            };
        },

      oneCallCostTextClass() {
            return {
                modeBigOneCallCost: this.mode === 'big',
                resultCalls: true,
            };
        },
    },

    methods: {
        choseTypeOfPackage() {
            this.$emit('changeTypeOfPackage');
        },
    },
};
</script>

<style scoped lang="less">
    .promo {
        display: flex;
        position: relative;
        padding: 0 15px 15px 15px;
        height: 462px;
        width: 100%;
        background: url('_img/background.png') center center no-repeat;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 7px;
        transition: all 0.7s;
        max-width: 250px;
    }

    .promoDefault30 {
        height: 502px;
        transform: translateY(-4.5%);
        padding-top: 15px;
    }

    .promo30 {
        background: url('_img/promo30Background.jpg') center center no-repeat;
    }

    .promo * {
        cursor: pointer;
    }

    .promo:hover {
        box-shadow: 0 0 5px #333;
        height: 502px;
        transform: translateY(-4.5%);
        padding-top: 15px;
    }

    .chosenPromo, .chosenPromo:hover {
        box-shadow: 0 0 0 3px #ffc000;
        height: 502px;
        transform:translateY(-4.5%);
        padding-top: 15px;
    }

    .notChosenPromo {
        opacity: 0.6;
    }

    .promoNumber {
        font-size: 40px;
    }

    .modeSmallPadding {
        padding: 15px 22px 11px 0;
    }

    .modeBigPadding {
        padding: 15px 22px 11px 0;
    }

    .headOfPromo {
        text-align: center;
        padding-bottom: 22px;
        border-bottom: 1px solid black;
    }

    .modeSmallImg {
        background: url('_img/smallFire.png') no-repeat 145px 19px;
    }

    .modeSmallImgPromo40 {
        background: url('_img/smallFire.png') no-repeat 145px 19px;
    }

    .modeBigImg {
        background: url('_img/bigFire.png') no-repeat 145px 5px;
        background-size: 40px;
    }

    .resultCalls {
        letter-spacing: 1px;
    }

    .bodyOfPromo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 22px;
    }

    .costOfPromoBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-bottom: 18px;
        font-size: 15px;

        p {
          font-size: 13px;
          margin-top: 5px;
        }
    }

    .costOfPromo {
        padding: 5px;
        font-size: 16px;
        border-radius: 5px;
        background: linear-gradient(180deg,#fbcc05 30%,#e8ab02 80%);
    }

    .promoProperty {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 15px;
    }

    .promoProperty p {
        margin: 3px 0;
        padding-left: 35px;
    }

    .chosenButton {
      margin-top: 20px;
    }

    .lprProperty {
        display: flex;
        flex-direction: column;
        margin-bottom: 22px;
        height: 70px;
        align-self: flex-start;
    }

    .lprPropertyPromo40 {
        display: flex;
        flex-direction: column;
        height: 36px;
        align-self: flex-start;
    }

    .lprProperty p {
        margin: 3px 0;
        padding: 0 0 0 37px;
    }

    .lprPropertyPromo40 p {
      margin: 3px 0;
      padding: 0 11px 0 37px;
    }

    .tickSmall, .modeBigOption {
        background: url('_img/tickSmall.png') no-repeat 15px 0;
    }

    .modeBigOption, .chosenPromoBlock, .modeBigOneCallCost, .promoProperty p, .costOfPromoBlock, .promoNumber {
        font-weight: bold;
    }

    .modeSmallOption {
        background: url('_img/notChosenOption.png') no-repeat 13px 0;
    }

    .modeBigOneCallCost {
        color: black;
    }

    .chosenPromoBlock {
        padding: 6px 15px;
        background: linear-gradient(to bottom, #ffd700 30%, #ffbb00 80%);
        font-size: 16px;
        border-radius: 5px;
    }

    .rubleSign {
      font-size: 30px;
    }

    @media screen and (max-width : 800px) {
        .promo {
            width: 230px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
</style>

<template>
  <modal name="commercialOffers"
         :width="490"
         height="auto"
         :scrollable="true"
         :adaptive="true"
         classes="modalStyle"
         @closed="closeModal">

    <div class="closeImg" @click="closeModal">
      <img src="./img/closeIcon.png" alt="close" width="30" height="28"/>
    </div>

    <section>
      <p class="head">Выберите интересующее вас КП</p>
      <div class="promo">
        <span class="head">Акции:</span>
        <div class="commercialOffers">
          <a href="/common_commercial_offer/promo500" target="_blank">500 звонков</a>
          <a href="/common_commercial_offer/promo1000" target="_blank">1000 звонков</a>
          <a href="/common_commercial_offer/promo1500" target="_blank">1500 звонков</a>
        </div>
      </div>
      <div class="package">
        <span class="head">PRO-пакеты:</span>
        <div class="commercialOffers">
          <a href="/common_commercial_offer/package19" target="_blank">35р/звонок</a>
          <a href="/common_commercial_offer/package30" target="_blank">56р/звонок</a>
          <a href="/common_commercial_offer/package40" target="_blank">58р/звонок</a>
        </div>
      </div>
    </section>

  </modal>
</template>

<script>
export default {
  name: 'CommercialOffers',

  methods: {
    closeModal() {
      window.document.querySelector('[data-tab_button="main"]').style.zIndex = '';
      this.$modal.hide('commercialOffers');
    },
  },
};
</script>

<style scoped lang="less">
  .promo {
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid;
  }

  .package {
    margin-top: 15px;
  }

  .promo, .package {
    & .head {
      font-weight: bold;
    }
  }

  .closeImg {
    position: absolute;
    z-index: 1;
    right: -10px;
    top: -10px;
    cursor: pointer;
  }

  .commercialOffers {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    & a {
      background: linear-gradient(0deg, #facf02 40%, #fce800);
      padding: 7px 10px;
      border-radius: 5px;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        box-shadow: 0 0 4px #333;
      }
    }
  }

  /deep/.modalStyle {
    border: 3px solid #FFD800;
    border-radius: 10px;
    overflow: visible;
    top: 30% !important;
    padding: 50px 65px;
    font-size: 15px;
  }

  /deep/.vm--overlay {
    height: 100% !important;
  }

  @media (max-width: 800px) {
    /deep/.modalStyle {
      margin: 0 auto !important;
      left: 0 !important;
    }

    /deep/.vue-dialog {
      margin: 0 auto !important;
      left: 0 !important;
      z-index: 999;
    }
  }
</style>
